import { Select, SelectProps } from '@chakra-ui/react';

import { formatCategory } from '../utils/text';

interface CategorySelectProps extends SelectProps {
  setCategory: any;
  isAllDisabled?: boolean;
}

const categories = [
  'all',
  'cameras',
  'lenses',
  'filters',
  'accessories',
  'video-control',
  'sound',
  'camera-motion',
  'lightning',
  'grip',
  'sfx',
  'power-distribution',
  'cars',
  'base-camp',
  'others',
];

export const CategorySelect = ({
  setCategory,
  value = 'all',
  width = '200px',
  isAllDisabled = false,
}: CategorySelectProps) => {
  return (
    <Select onChange={setCategory} value={value} width={width} name="category">
      {categories.map((category, i) => (
        <option key={i} value={category} disabled={isAllDisabled && category === 'all'}>
          {formatCategory(category)}
        </option>
      ))}
    </Select>
  );
};
